import { getFirstName } from '@blissbook/lib/name'
import { immerable } from 'immer'
import { Person } from './person'
import { fromJSON, mapJSONValues } from './util/json'

type ISessionUser = {
  id: number
  type: 'superUser' | 'user'
  authType: string
  authId: string

  email?: string
  fullName?: string
  roleId?: string
}

export class Session {
  expiresAt?: Date
  isImpersonating: boolean
  permissionIds: string[]
  person?: Person
  user?: ISessionUser

  static get ATTRIBUTES() {
    return {
      expiresAt: fromJSON.date,
      person: (v: any) => Person.fromJSON(v),
    }
  }

  static mapJSON(json: any) {
    return mapJSONValues(json, Session.ATTRIBUTES)
  }

  static fromJSON(json: any): Session {
    return Object.assign(new Session(), Session.mapJSON(json))
  }

  get email() {
    const { person, user } = this
    return person?.email || user?.email
  }

  get firstName() {
    return getFirstName(this.fullName)
  }

  get fullName() {
    const { person, user } = this
    return person?.fullName || user.fullName
  }

  get canAdminBlissbook() {
    return this.isSuperUser && this.permissionIds.includes('blissbook.admin')
  }

  get isSuperUser() {
    return this.user?.type === 'superUser'
  }

  get personId() {
    const { person } = this
    return person?.id
  }

  can(permissionId: string) {
    return this.permissionIds.includes(permissionId)
  }
}

// @ts-ignore: immerable
Session[immerable] = true
