import dedent from 'dedent'

export const billingConfig: Record<string, any> = {
  daysPerYear: 365,
  monthsPerYear: 12,
  minCredits: 125,
  termStartCount: 100, // Default
  termMonths: 12, // Default
  minTermMonths: 1,
  maxTermMonths: 60,
  minPeopleCount: 1,
  maxPeopleCount: 20000,
  monthlyCreditPrice: 0.0256438356164384, // $9.36 per user per year = (9.36 / 365).toFixed(16)
  yearlyCreditPrice: 0.0219178082191781, // $8 per user per year = (8 / 365).toFixed(16)
  maxCreditCard: 10000,
  gracePeriodDays: 7,
  paymentDays: 30, // Default
  yearlyGrowthRate: 0.05, // Default
  minYearlyGrowthRate: 0,
  maxYearlyGrowthRate: 0.3,
  remitTo: dedent`
    Rocket Whale Products, LLC
    1442 Miller Ave NE
    Atlanta, GA 30307
    United States
  `,
}
