const suffixes = [
  // Roman numerals (generational)
  'ii',
  'II',
  'iii',
  'III',
  'iv',
  'IV',
  'v',
  'V',
  'vi',
  'VI',
  'vii',
  'VII',

  // generational
  'Jr',
  'JR',
  'Sr',
  'SR',

  // Professional
  'CEO',
  'CPA',
  'Esq',
  'Esquire',
  'MBA',
  'MD',
  'PhD',
  'PsyD',

  // Corporate
  'Inc',
  'INC',
  'LLC',
  'Ltd',
  'LTD',

  // Other (in DB)
  'CSW',
  'LCSW',
  'LMFT',
  'OCSA',
  'PA-C',
  'PMHNP-BC',
]

export function isSuffix(lastName: string) {
  // Otherwise, look it up in a known list
  const suffix = lastName.replace(/[.]/g, '')
  return suffixes.includes(suffix)
}
