import { useContext, useReducer, useRef } from 'react'
import { useIsomorphicLayoutEffect } from '../hooks'
import { StoreContext } from './provider'
import type { Store } from './store'
import { type Selector, getIn } from './util'

export const useStore = <T extends object>(selector?: Selector<T>) => {
  const store: Store<T> = useContext(StoreContext)
  const [renderCount, forceRender] = useReducer((s) => s + 1, 0)
  const latestSelector = useRef<Selector<T>>()
  const latestSelectedState = useRef<any>()

  // Select the state
  if (selector !== latestSelector.current || !renderCount) {
    latestSelector.current = selector
    latestSelectedState.current = getIn(store.state, selector)
  }

  // Look for change
  useIsomorphicLayoutEffect(
    () =>
      store.subscribe((state) => {
        const selectedState = getIn(state, latestSelector.current)
        if (selectedState !== latestSelectedState.current) {
          latestSelectedState.current = selectedState
          forceRender()
        }
      }),
    [store],
  )

  return latestSelectedState.current
}
