import { colors } from '@blissbook/ui/branding'
import tinycolor from 'tinycolor2'

const defaultContrastColors = [colors.black, colors.white]

// Get the color that constrasts against the given color best
export const getContrastColor = (
  color: string,
  colors = defaultContrastColors,
) => {
  if (!color) return
  const brightness = tinycolor(color).getLuminance()
  const getDelta = (color: string) =>
    Math.abs(brightness - tinycolor(color).getLuminance())
  return colors.reduce((best, next) =>
    getDelta(next) > getDelta(best) ? next : best,
  )
}
