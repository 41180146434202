export type LogoImage = {
  width?: number
  height?: number
}

export type LogoImageWidthOptions = {
  linearCoefficient?: number
  minWidth: number
  maxWidth: number
  quadraticCoefficient?: number
}

const calcIdealLogoWidth = (
  image: LogoImage,
  {
    linearCoefficient = 15,
    minWidth,
    quadraticCoefficient = 1,
  }: LogoImageWidthOptions,
) => {
  const ratio = image.width / image.height
  return (
    minWidth + linearCoefficient * ratio + quadraticCoefficient * ratio ** 2
  )
}

export const calculateLogoWidth = (
  image: LogoImage,
  options: LogoImageWidthOptions,
) => {
  const { minWidth, maxWidth } = options

  // If no width, cut the difference
  if (!image.width || !image.height) return (minWidth + maxWidth) / 2

  // Calculate the ideal and then split the difference
  let width = calcIdealLogoWidth(image, options)
  width = Math.min(width, maxWidth)
  return Math.round(width)
}
