import { immerable } from 'immer'
import moment from 'moment'
import { billingConfig } from './billing/config'
import { CreditCard } from './billing/creditCard'
import {
  calculateDaysLeft,
  convertGrowthRateFromMonthlyToYearly,
} from './billing/util'
import { fromJSON, mapJSONValues } from './util/json'

export class Billing {
  canceledAt: Date
  credits: number
  dailyCredits: number
  exempt: boolean
  monthlyCreditPrice: number
  monthlyGrowthRate: number
  paymentMethod: string
  peopleCount: number
  terminatedAt?: Date
  termMonths: number
  termMonthlyGrowthRate: number
  yearlyCreditPrice: number

  static get ATTRIBUTES() {
    return {
      expiredAt: (v: string) => fromJSON.date(v),
      canceledAt: (v: string) => fromJSON.date(v),
      terminatedAt: (v: string) => fromJSON.date(v),
      creditCard: (v: any) => CreditCard.fromJSON(v),
    }
  }

  static mapJSON(json: any) {
    return mapJSONValues(json, Billing.ATTRIBUTES)
  }

  static fromJSON(json: any) {
    return Object.assign(new Billing(), Billing.mapJSON(json))
  }

  getMonthlyCreditPrice() {
    return this.monthlyCreditPrice || billingConfig.monthlyCreditPrice
  }

  getYearlyCreditPrice() {
    return this.yearlyCreditPrice || billingConfig.yearlyCreditPrice
  }

  get monthlyPerUserPrice() {
    return this.getMonthlyCreditPrice() * billingConfig.daysPerMonth
  }

  get yearlyPerUserPrice() {
    return this.getYearlyCreditPrice() * billingConfig.daysPerYear
  }

  get monthlyPrice() {
    return this.monthlyPerUserPrice * this.dailyCredits
  }

  get yearlyPrice() {
    return this.yearlyPerUserPrice * this.dailyCredits
  }

  get yearlyGrowthRate() {
    return convertGrowthRateFromMonthlyToYearly(this.monthlyGrowthRate)
  }

  get termYearlyGrowthRate() {
    return convertGrowthRateFromMonthlyToYearly(this.termMonthlyGrowthRate)
  }

  get isYearly() {
    return this.termMonths >= billingConfig.monthsPerYear
  }

  get daysLeft() {
    return calculateDaysLeft(this.credits, this.peopleCount)
  }

  get renewsAt() {
    const { daysLeft } = this
    if (daysLeft < 0) return null
    return moment().startOf('day').add(daysLeft, 'days').toDate()
  }

  get isCreditCard() {
    return this.paymentMethod === 'credit-card'
  }

  get isCheck() {
    return this.paymentMethod === 'check'
  }

  get isPaid() {
    return this.credits !== null
  }

  get isTrial() {
    return !this.isValid && !this.terminatedAt
  }

  get isValid() {
    return this.exempt || this.isPaid
  }

  get isFirstTime() {
    return !this.isPaid && !this.terminatedAt
  }

  get isStopped() {
    return !!this.canceledAt || !!this.terminatedAt
  }
}

// @ts-ignore: immerable
Billing[immerable] = true
