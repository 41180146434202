import produce, { enableAllPlugins } from 'immer'
enableAllPlugins() // Required for IE

export class Store<StoreState extends object> {
  // Singleton Instance -------------------------------------------------------

  static instance: Store<any> // Deprecated: avoid

  static create<StoreState extends object>(initialState: StoreState) {
    const store = new Store<StoreState>(initialState)
    Store.instance = store
    return store
  }

  // Prototype ----------------------------------------------------------------

  constructor(initialState: StoreState) {
    this.listeners = []
    this.state = initialState
  }

  subscribe(listener: (state: StoreState) => void) {
    this.listeners.push(listener)
    return () => {
      const index = this.listeners.indexOf(listener)
      this.listeners.splice(index, 1)
    }
  }

  produce(recipe: (draft: StoreState) => void) {
    const state = produce(this.state, recipe)
    this.setState(state)
  }

  setState(state: StoreState) {
    // If no changes, just return the current state
    if (state === this.state) return this.state

    // Set the new state and inform all listeners
    this.state = state
    const listeners = this.listeners.slice()
    for (const listener of listeners) {
      listener(this.state)
    }

    // Return the state
    return this.state
  }

  listeners: ((state: StoreState) => void)[]
  state: StoreState
}
