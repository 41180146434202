import React from 'react'
import type { Store } from './store'

export const StoreContext = React.createContext(undefined)

export const StoreProvider: React.FC<{
  store: Store<any>
}> = ({ children, store }) => (
  <StoreContext.Provider value={store}>{children}</StoreContext.Provider>
)
