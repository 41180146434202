import nth from 'lodash/nth'
import { isSuffix } from './suffix'

function getNames(fullName: string) {
  return fullName.split(' ').filter(Boolean)
}

function formatName(name: string) {
  const punctuation = '-–—,.'
  const leading = new RegExp(`^[${punctuation}]`)
  const trailing = new RegExp(`[${punctuation}]$`)
  return name.replace(leading, '').replace(trailing, '')
}

function nthName(names: string[], n: number) {
  const name = nth(names, n)
  if (!name) return
  return formatName(name)
}

export function getFirstName(fullName?: string) {
  if (!fullName) return
  const names = getNames(fullName)
  return nthName(names, 0)
}

export function getLastName(fullName?: string) {
  if (!fullName) return

  const names = getNames(fullName)
  if (names.length === 1) return

  let lastName = nthName(names, -1)
  if (names.length > 1 && isSuffix(lastName)) {
    lastName = `${nth(names, -2)} ${lastName}`
  }

  return lastName
}

export function renderGreeting(displayName?: string) {
  // No name, just say hello
  if (!displayName) return 'Hello'

  // Otherwise, to the first name
  const firstName = getFirstName(displayName)
  return `Hi ${firstName}`
}

// Convert the full name from "Last, First" to "First Last"
export function convertFullName(fullName: string) {
  // Can only have 1 comma
  const parts = fullName.split(',').map((part) => part.trim())
  if (parts.length !== 2) return fullName

  // Cannot have these charaters
  const notNameChars = ['(', ')']
  const isNotName = notNameChars.some((char) => fullName.includes(char))
  if (isNotName) return fullName

  // Detect professional degrees
  const [firstPart, lastPart] = parts
  if (!lastPart.length) return firstPart
  if (isSuffix(lastPart)) return fullName
  return [lastPart, firstPart].join(' ')
}

/** Get this person's initials */
export function getInitials(fullName: string) {
  const firstName = getFirstName(fullName)
  const lastName = getLastName(fullName)
  if (!lastName) return firstName[0]
  return firstName[0] + lastName[0]
}
