import moment from 'moment'

export type CreditCardExpiration = {
  exp_month: number
  exp_year: number
}

export function getExpirationDate(card: CreditCardExpiration) {
  return moment({
    year: card.exp_year,
    month: card.exp_month - 1,
  })
    .endOf('month')
    .toDate()
}

export function parseExpirationDate(date: Date) {
  return {
    exp_month: moment(date).month() + 1,
    exp_year: moment(date).year(),
  }
}

export function isCreditCardExpired(card?: CreditCardExpiration, date?: Date) {
  // If no card, not expired
  if (!card) return false

  // Check the expiration
  const expiresAt = getExpirationDate(card)
  return moment(date).valueOf() >= expiresAt.valueOf()
}

export interface ICreditCard extends CreditCardExpiration {
  brand: string
}

export class CreditCard implements ICreditCard {
  brand: string
  exp_month: number
  exp_year: number

  static fromJSON(json: ICreditCard) {
    return Object.assign(new CreditCard(), json)
  }

  get brandAbbr() {
    return getBrandAbbr(this.brand)
  }
}

export function getBrandAbbr(brand: string) {
  if (/mastercard/i.test(brand)) return 'MC'
  if (/american express/i.test(brand)) return 'AMEX'
  return brand
}
