import get from 'lodash/get'
import isFunction from 'lodash/isFunction'
import set from 'lodash/set'

export type SelectorFunction<T extends object> = (state: T) => any

export type Selector<T extends object> = string | SelectorFunction<T>

export const getIn = <T extends object>(state: T, selector?: Selector<T>) => {
  if (!selector) return state
  return isFunction(selector) ? selector(state) : get(state, selector)
}

export const patchIn = <T extends object>(
  state: T,
  changes: Record<string, any>,
  patchKeys: string[] = [],
) => {
  const keys = Object.keys(changes)
  for (const key of keys) {
    const currValue = get(state, key)
    const value = changes[key]
    if (currValue && patchKeys.includes(key)) {
      patchIn(currValue, value)
    } else {
      set(state, key, value)
    }
  }
}
